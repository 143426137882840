.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}
.button {
  background-color: #FAE7DA;
  font-size: 35px;
  line-height: 1.14285714;
  font-weight: 300;
  cursor: pointer;
  color: #929291;
  padding: 12px 26px 8px;
  appearance: none;
  border-radius: 0;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  border: none;
}
.button:hover,
.button:focus {
  background-color: #929291;
  color: #fff;
}
.area--one .unitTwo--backgroundPink .button,
.area--one .unitThree .button,
.area--one .unitFour--statusSold .button {
  border: 2px solid #929291;
}
.area--one .unitTwo--backgroundGray .button,
.area--one .unitFour--statusRented .button {
  border: 1px solid #FAE7DA;
}
@media (max-width: 1023px) {
  .button {
    font-size: 30px;
    line-height: 1.13333333;
    padding: 8px 16px 4px;
  }
}
@media (max-width: 767px) {
  .button {
    padding-top: 5px;
    padding-bottom: 1px;
  }
}
div.navi {
  float: left;
}
div.navi > .item {
  float: left;
}
div.navi > .item > .menu {
  display: block;
}
@media (min-width: 1024px) {
  .togglenavigation,
  .mobile-navigation {
    display: none;
  }
}
.navigation div.sub1 > .item {
  margin-right: 50px;
}
.navigation div.sub1 > .item.exit {
  margin-right: 0 !important;
}
.navigation div.sub1 > .item > .menu {
  text-decoration: none;
  font-size: 30px;
  line-height: 1.4;
  text-transform: uppercase;
}
.navigation div.sub1 > .item > .menu.path {
  color: #FAE7DA;
}
@media (max-width: 1399px) {
  .navigation div.sub1 > .item {
    margin-right: 40px;
  }
  .navigation div.sub1 > .item > .menu {
    font-size: 24px;
    line-height: 1.25;
  }
}
.area--one {
  width: calc(100% + 50px);
  margin-left: -25px;
}
.area--one .unit {
  margin-left: 25px;
  margin-right: 25px;
  width: calc(100% - 50px);
}
.area--one .unitOne--1-2 {
  width: calc(50% - 50px);
}
.area--one .unitOne--2-3 {
  width: calc(((100% / 3) * 2) - 50px);
}
.area--one .unitOne--1-3 {
  width: calc((100% / 3) - 50px);
}
.area--one .unitTwo--1-2 {
  width: calc(50% - 50px);
}
.area--one .unitFour {
  width: calc(50% - 50px);
}
/*# sourceMappingURL=./screen-large.css.map */